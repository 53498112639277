import { eventQBus } from "../types/EventQBus";
import { Tile } from "./Tile";
import { CompareSheetComponent } from "./CompareSheetComponent";
import { breakpoint } from "@otto-ec/global-resources/breakpoint";

export default class CompareSheet {
  private static compareSheet: CompareSheet | undefined;

  private readonly component;

  constructor(component: CompareSheetComponent) {
    /*                                                                             */
    eventQBus.on("ftfind.tile.compare", () => {
      if (!component.isOpen()) {
        component.forEachTile((tile: Tile) => tile.load());
        component.open();
      }
    });
    eventQBus.on("ftfind.tile.compared", ({ variationId }) => {
      this.addProductVariation(variationId);
    });

    eventQBus.on("ftfind.tile.uncompared", ({ variationId }) => {
      this.removeProductVariation(variationId);
    });

    /*                                                                                                          */
    if (breakpoint.isMedium() || breakpoint.isSmall()) {
      eventQBus.on("ftnav.navigation.open", () => {
        component.hide();
      });
      eventQBus.on("ftnav.navigation.close", () => {
        component.unhide();
      });
    }

    this.component = component;
  }

  public static init(): CompareSheet | undefined {
    if (!CompareSheet.isComparisonPage()) {
      return;
    }

    if (CompareSheet.compareSheet === undefined && !CompareSheetComponent.existsInDom()) {
      CompareSheet.compareSheet = new CompareSheet(
        new CompareSheetComponent(
          CompareSheet.onCurtainClick,
          CompareSheet.toggleSheet,
          CompareSheet.removeAll,
          CompareSheet.startComparison,
          CompareSheet.bannerClicked
        )
      );
    }

    return CompareSheet.compareSheet;
  }

  static isComparisonPage(): boolean {
    const comparisonIdElement = document.getElementById("reptile-comparison-id");
    return !!comparisonIdElement && !!comparisonIdElement.dataset && !!comparisonIdElement.dataset.comparisonId;
  }

  public addProductVariation(variationId: string) {
    if (!this.component.isMinimized() && !this.component.isOpen()) {
      this.component.minimize();
    }

    if (!this.component.hasTile(variationId)) {
      const tile = new Tile(variationId, () => {
        eventQBus.emit("ftfind.tile.uncompare", {
          variationId: variationId,
          source: "sheet",
        });
      });
      this.component.addTile(tile);
      if (!this.component.isMinimized()) {
        tile.load();
      }
    }
  }

  public removeProductVariation(variationId: string) {
    if (this.component.hasTile(variationId)) {
      this.component.removeTile(variationId);
    }
    if (this.component.amount === 0) {
      this.component.close();
    }
  }

  /*                 */

  static onCurtainClick(this: CompareSheetComponent) {
    if (this.amount > 0) {
      this.minimize();
    } else {
      this.close();
    }
  }

  static startComparison(this: CompareSheetComponent) {
    eventQBus.emit("ftfind.comparison.start");
    eventQBus.emit("reptile.tilelist-cache.store");
  }

  static bannerClicked(this: CompareSheetComponent) {
    eventQBus.emit("tracking.bct.submitEvent", {
      san_ComparisonActivity: "banner_click",
    });
  }

  static removeAll(this: CompareSheetComponent) {
    this.forEachTile((tile, variationId) => {
      /*                                                                */
      eventQBus.emit("ftfind.tile.uncompare", { variationId: variationId, source: "sheet_all" });
    });
    eventQBus.emit("tracking.bct.submitEvent", { san_ComparisonActivity: "sheet_delete_all" });
  }

  static toggleSheet(this: CompareSheetComponent) {
    if (this.isOpen()) {
      this.minimize();
      eventQBus.emit("tracking.bct.submitEvent", {
        san_ComparisonActivity: "sheet_close",
      });
    } else {
      this.open();
      this.forEachTile((tile: Tile) => tile.load());
      eventQBus.emit("tracking.bct.submitEvent", {
        san_ComparisonActivity: "sheet_open",
      });
    }
  }
}
