import { SponsoredSheetData } from "../types/SponsoredSheet";
import PaliSheet from "./PaliSheet";

export default class SponsoredSheet {
  private static readonly headline = "Warum sehe ich diese Fläche?";
  private static readonly tracking = { san_ListLayer: ["sponsored product"] };

  public static open(data: SponsoredSheetData) {
    PaliSheet.open(this.headline, this.content(data.funderLegalName, data.advertiserLegalName), this.tracking);
  }

  private static readonly content = (funderLegalName: string, advertiserLegalName: string) => `
    <p class="pl_copy100">Hier handelt es sich um eine gesponserte Fläche. Das sind Werbeanzeigen, die von unseren Lieferanten und Händlern finanziert werden.</p>
    <p class="pl_copy100">Wir achten darauf, dass diese Anzeigen zu deinem Interesse bzw. deiner Suche passen.</p>
    <h1 class="pl_headline100 pl_mt150 pl_mb50">Über den Werbetreibenden</h1>
    <ul class="pl_list--unordered">
      <li style="margin-bottom: 0"><span class="pl_headline50">Name des Werbetreibenden:</span> ${advertiserLegalName}</li>
      <li><span class="pl_headline50">Wer hat für diese Anzeige bezahlt:</span> ${funderLegalName}</li>
    </ul>
  `;
}
