/**
 *
 *
 *
 *
 */
export function clean(object: object): object {
  const clonedObject = { ...object };

  [].forEach.call(
    Object.keys(clonedObject),
    (key) =>
      (clonedObject[key] === "" || clonedObject[key] == null || clonedObject[key] === undefined) &&
      delete clonedObject[key]
  );

  return clonedObject;
}
