import { eventQBus } from "../types/EventQBus";
import { OnTileLoaded } from "../types/Tile";
import { Action } from "../types/Tracking";

export type ColorChangeTrackingLabels = {
  san_Interaction?: "product_color_change";
  san_ProductColorPosition?: number;
  san_ProductColorCount?: number;
};

/**
 *
 *
 *
 *
 */
function replaceNode(node: Node, replacement: Node): void {
  const range = document.createRange();
  /*                                 */
  range.selectNode(node);

  /*           */
  range.deleteContents();
  range.insertNode(replacement);
}

/**
 *
 *
 *
 *
 *
 *
 */
function toggleItem(activatedName: string, selectedClass: string, elem: HTMLElement): boolean {
  const { colorName } = elem.dataset;
  const activate = colorName === activatedName;
  const isActive = !elem.classList.contains(selectedClass);
  elem.classList.toggle(selectedClass, activate);
  return activate !== isActive;
}

/**
 *
 *
 *
 *
 *
 */
function updateContent(activatedName: string, selectedClass: string, switchableItems: NodeListOf<Element>) {
  return Array.prototype.map
    .call(switchableItems, toggleItem.bind(switchableItems.item, activatedName, selectedClass))
    .reduce((acc, cur) => acc || cur, false);
}

/**
 *
 *
 */
function applyPreviousScrollPosition(productArticle: HTMLElement, colorThumbs: HTMLElement) {
  const scrollPosString = productArticle.dataset.lastScrollPosition;
  if (!!scrollPosString) {
    colorThumbs.scrollLeft = parseInt(scrollPosString, 10);
  }
}

/**
 *
 *
 */
function storeLastScrollPosition(productArticle: HTMLElement, colorThumbs: HTMLElement) {
  productArticle.dataset.lastScrollPosition = colorThumbs.scrollLeft + "";
}

/**
 *
 *
 *
 *
 */
function onChange(productArticle: HTMLElement, event: Event) {
  const target = event.target as HTMLElement;
  const colorThumb = target.closest(".reptile_colorThumb") as HTMLElement;

  if (colorThumb) {
    storeLastScrollPosition(productArticle, colorThumb.closest(".reptile_colorThumbs") as HTMLElement);
  }

  if (colorThumb) {
    const { colorName, variationId } = colorThumb.dataset;

    if (colorName && variationId && !colorThumb?.classList.contains("reptile_colorThumb--selected")) {
      const tile = productArticle.querySelector(`.find_tile[data-variation-id="${variationId}"]`) as HTMLElement;

      if (tile.classList.contains("js_reptile_tile--lazy")) {
        eventQBus.emit("ftfind.tile.load", {
          target: tile,
        });
      } else {
        updateContent(colorName, "find_tile--visible", productArticle.querySelectorAll(".find_tile"));
        applyPreviousScrollPosition(productArticle, tile.querySelector(".reptile_colorThumbs") as HTMLElement);
      }

      /*                             */
      updateContent(colorName, "reptile_colorThumb--selected", productArticle.querySelectorAll(".reptile_colorThumb"));
      trackColorSwitch(variationId, productArticle);
    }
  }
}

function registerChangeListener(productArticle: HTMLElement, colorThumbsElement: HTMLElement) {
  const changeListener = onChange.bind(colorThumbsElement, productArticle);
  colorThumbsElement.addEventListener("click", changeListener);
}

/**
 *
 *
 */
function findFirstIndex(nodes: NodeListOf<Element>, classname: string) {
  for (let i = 0; i <= nodes.length; i++) {
    const node = nodes[i];
    if (node.classList.contains(classname)) {
      return i;
    }
  }

  return null;
}

/**
 *
 *
 */
function trackColorSwitch(variationId: string, productArticle: HTMLElement) {
  const colorThumbs = productArticle.querySelectorAll(".find_tile--visible .reptile_colorThumb");
  const { amountColors, localListPosition } = productArticle.dataset;
  const position = findFirstIndex(colorThumbs, "reptile_colorThumb--selected");
  if (typeof position === "number" && amountColors && localListPosition) {
    const colorChangeTrackingData: ColorChangeTrackingLabels = {
      san_Interaction: "product_color_change",
    };
    const colorChangeFeatureTracking: Action = {
      name: "change",
      features: [
        {
          id: "find_tile_" + localListPosition,
          name: "tile",
          status: "changed",
          position: +localListPosition,
          variationId: variationId,
          labels: {
            san_ProductColorPosition: position + 1,
            san_ProductColorCount: +amountColors,
          },
        },
      ],
    };
    eventQBus.emit("tracking.bct.submitAction", colorChangeTrackingData, colorChangeFeatureTracking);
  }
}

const colorsThumbLinkClass = "reptile_colorThumbLink";

function appendLessColorsLink(target: HTMLElement, container: HTMLElement, colorThumbs: Node): Node {
  if (!colorThumbs) {
    return colorThumbs;
  }
  const productLink = target.querySelector(".find_tile__productLink") as HTMLAnchorElement;
  const tiles = parseInt(container.dataset.amountColors as string, 10) || 0;
  const restTiles = tiles - 5;

  if (restTiles > 0 && !!productLink && productLink.href !== "") {
    const div = document.createElement("div");
    div.classList.add(colorsThumbLinkClass);

    const a = document.createElement("a");
    a.classList.add("pl_copy75");
    a.href = productLink.getAttribute("href") as string;
    a.title = productLink.getAttribute("title") as string;
    a.textContent = "+" + restTiles;

    div.append(a);
    colorThumbs.appendChild(div);
  }

  return colorThumbs;
}

/*  */

function onTileLoaded(event: OnTileLoaded) {
  const target = event.target as HTMLElement;
  const container = target.closest(".js_find_colorChange");
  if (container) {
    const { colorName } = target.dataset;
    const colorThumbsPlaceholder = target.querySelector(".find_tile__colorThumbs--placeholder") as Node;
    let colorThumbs = container.querySelector(".reptile_colorThumbsContainer .reptile_colorThumbs") as Node;
    colorThumbs = colorThumbs.cloneNode(true);

    /*                */
    if (!!colorThumbsPlaceholder && !!colorThumbs) {
      /*                      */
      colorThumbs = appendLessColorsLink(target, container as HTMLElement, colorThumbs);

      replaceNode(colorThumbsPlaceholder, colorThumbs);

      /*                       */
      const colorThumbsInTarget = target.querySelector(".reptile_colorThumbs") as HTMLElement;
      registerChangeListener(container as HTMLElement, colorThumbsInTarget);

      /*                           */
      window.setTimeout(() => {
        updateContent(colorName as string, "find_tile--visible", container.querySelectorAll(".find_tile"));
        applyPreviousScrollPosition(container as HTMLElement, colorThumbsInTarget);
      }, 10);
    }
  }
}

/**
 *
 *
 *
 */
function create(productArticle: HTMLElement) {
  const colorThumbsElements = productArticle.querySelectorAll(".reptile_tile__container .reptile_colorThumbs");

  Array.from(colorThumbsElements).forEach((colorThumbsElement) =>
    registerChangeListener(productArticle, colorThumbsElement as HTMLElement),
  );
}

function onTileListLoaded() {
  Array.from(document.getElementsByClassName("js_find_colorChange")).forEach((productArticle) =>
    create(productArticle as HTMLElement),
  );
}

export function registerColorChange() {
  eventQBus.on("ftfind.tilelist.restored", onTileListLoaded);
  eventQBus.on("ftfind.tile.loaded", onTileLoaded);
}
