import { eventQBus } from "../types/EventQBus";
import { OnDiscountBenefitSheetOpen } from "../types/Tile";
import DiscountBenefitSheet from "./DiscountBenefitSheet";

export function onDiscounBenefitSheetOpen(data: OnDiscountBenefitSheetOpen) {
  if (!data || !data.target || !(data.target instanceof HTMLElement)) {
    return;
  }
  const benefitId = data.benefitId;
  const variationId = data.variationId;
  const avContent = document.getElementById("avContent") as HTMLElement;
  const rule = avContent.dataset.rule?.toString() || "";
  DiscountBenefitSheet.open(data.target, benefitId, rule, variationId);
}

export function registerDiscountBenefitSheet() {
  eventQBus.on("reptile.kuvo-sheet.open", onDiscounBenefitSheetOpen);
}
