import { eventQBus } from "../types/EventQBus";
import { Storage } from "@otto-ec/global-resources/storage";
import { toggle } from "@otto-ec/global-resources/toggle";
import { event } from "@otto-ec/global-resources/event";
import { fragment } from "@otto-ec/global-resources/fragment";

const storage = new Storage(window.sessionStorage);
const ID_KEY = "san_cacheId";
const CONTENT_KEY = "san_cacheContent";
const SCROLL_KEY = "san_verticalScrollPos";
const CACHE_DURATION = 5;
const date = new Date();
const isApp = document.querySelector("html")?.classList.contains("app");

let resultSection: Element | null;

export function generateHash(value: string): number {
  let hash = 0;
  let i;
  let chr;
  let len;

  if (value.length === 0) {
    return hash;
  }

  for (i = 0, len = value.length; i < len; i++) {
    chr = value.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; /*                       */
  }

  return hash;
}

function getMinutes() {
  return Math.floor(date.getTime() / 1000 / 60);
}

function safeSetItem(key: string, val: string) {
  try {
    storage.setItem(key, val);
  } catch (err) {
    console.error(err);
  }
}

function safeGetItem(key: string) {
  try {
    return storage.getItem(key);
  } catch (err) {
    console.error(err);
  }
}

function cacheIsValid() {
  const keys = safeGetItem(ID_KEY);
  let result;

  if (keys != null) {
    result = keys.split("_");
    const durationCheck = getMinutes() - parseInt(result[0], 10) <= CACHE_DURATION;
    const htmlHashCheck = parseInt(result[1], 10) === generateHash(location.href.replace(location.hash, ""));
    return durationCheck && htmlHashCheck;
  }

  return false;
}

function updateCacheContent() {
  if (resultSection != null) {
    safeSetItem(CONTENT_KEY, resultSection.innerHTML);
  }

  if (isApp || toggle.get("FTFIND_ENABLE_SCROLL_RESTORE")) {
    safeSetItem(SCROLL_KEY, window.scrollY.toString());
  }
}

function updateCacheKey(hash: number) {
  safeSetItem(ID_KEY, getMinutes() + "_" + hash);
}

function refreshCacheKey() {
  updateCacheKey(generateHash(location.href.replace(location.hash, "")));

  if (safeGetItem(CONTENT_KEY) != null) {
    storage.removeItem(CONTENT_KEY);
  }
}

function handleClick() {
  if ("scrollRestoration" in history) {
    history.scrollRestoration = "manual";
  }

  if (resultSection != null) {
    updateCacheContent();
  }
}

function restoreContentFromCache() {
  const storedHtml = safeGetItem(CONTENT_KEY);

  if (resultSection != null && storedHtml != null) {
    resultSection.innerHTML = storedHtml;
    return true;
  }

  return false;
}

function addTrackingFragmentToHash() {
  const removeLabelHashFragment = '#t={"ts_RemoveLabels":"wk.nav_UnfilteredSelectionRule"}';

  if (history != null && history.replaceState != null) {
    history.replaceState(history.state, document.title, fragment.push(removeLabelHashFragment));
  }
}

/**
 *
 */
function informMASAboutCacheUsage(usedCache: boolean) {
  eventQBus.onModuleLoaded("reptile.tilelist.cache", ["ft3.promo.widgetspinner"], () => {
    eventQBus.emit(usedCache ? "reptile.tilelist-cache.used" : "reptile.tilelist-cache.ignored");
  });
}

export function registerTilelistCache(data: unknown) {
  const delegationElement = document.getElementById("reptile-search-result");
  let triggerCacheUsageEvent = false;
  resultSection = document.getElementById("reptile-tilelist");

  if (delegationElement != null) {
    if (toggle.get("FTFIND_REMOVE_UNFILTERED_ON_BROWSER_BACK", false)) {
      event.delegate(delegationElement, "click", ".product a", addTrackingFragmentToHash);
    }

    if (storage.isAvailable) {
      eventQBus.on("reptile.tilelist-cache.store", handleClick);

      event.delegate(delegationElement, "click", ".product a", handleClick);
      event.delegate(delegationElement, "click", ".js_reptile_tile__similarProductsButton", handleClick);

      /*                                                                  */
      if (cacheIsValid() && data === undefined) {
        if (restoreContentFromCache()) {
          eventQBus.emit("ftfind.tilelist.restored");
          triggerCacheUsageEvent = true;

          if (isApp || toggle.get("FTFIND_ENABLE_SCROLL_RESTORE")) {
            const scrollCacheValue = safeGetItem(SCROLL_KEY);
            if (scrollCacheValue != null) {
              const scrollValue = parseInt(scrollCacheValue);
              if (scrollValue) {
                setTimeout(function () {
                  window.scrollTo(0, scrollValue);
                }, 0);
              }
            }
          }
        }
      } else {
        refreshCacheKey();
      }
    }

    informMASAboutCacheUsage(triggerCacheUsageEvent);
  }

  if (resultSection != null) {
    resultSection.classList.remove("reptile_tilelist--hidden");
  }
}
