import { eventQBus } from "../types/EventQBus";

const TILELIST_TILES = ".js_reptile_tile--lazy.find_tile--visible";
const OBSERVER_OPTIONS = {
  root: null,
  rootMargin: "300px 0px 300px 0px",
  threshold: 0,
};

function onIntersect(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const tile = entry.target as HTMLElement;

      eventQBus.emit("ftfind.tile.load", {
        target: tile,
      });

      observer.unobserve(tile);
    }
  });
}

function onTileListLoaded(tileSelector: string) {
  const lazyTiles = document.querySelectorAll(tileSelector);
  const observer = new IntersectionObserver(onIntersect, OBSERVER_OPTIONS);

  Array.from(lazyTiles).forEach((tile) => observer.observe(tile));
}

export function registerTileLoading() {
  onTileListLoaded(TILELIST_TILES); /*                                                                            */
  eventQBus.on("ftfind.tilelist.loaded", () => onTileListLoaded(TILELIST_TILES));
  eventQBus.on("reptile.similar_product_sheet.loaded", () => onTileListLoaded(".reptile_similar-product-sheet " + TILELIST_TILES));
}
