import { OnSwitchChange } from "../types/Assets";
import { eventQBus } from "../types/EventQBus";
import { cookie } from "@otto-ec/global-resources/cookie";

const switchCheckedLabel = "Produktvergleich aktiviert. Füge jetzt Produkte von dieser Liste hinzu. ";
const switchUncheckedLabel =
  "Du kannst dich nicht entscheiden? Nutze unseren Produktvergleich und füge Produkte dem Vergleich hinzu.";
const pcbToggle = "reptile-pcb-toggle";
const productComparisonEnabledCookie = "productComparisonEnabled";

export function registerProductComparisonCookieCheck() {
  initProductCompareVisibility(); /*                                                                            */
  eventQBus.on("ftfind.tilelist.loaded", initProductCompareVisibility);
}

export function registerProductComparisonBanner() {
  eventQBus.on("assets.switch.changed", toggleProductCompareVisibility);

  /*                                                                                */
  eventQBus.on("ftfind.tilelist.loaded", () => {
    eventQBus.emit("assets.switch.init");
  });
  eventQBus.on("ftfind.tilelist.restored", () => {
    eventQBus.emit("assets.switch.init");
  });
}

export function initProductCompareVisibility() {
  if (cookie.get(productComparisonEnabledCookie) === "true") {
    toggleProductCompareVisibility({ target: { id: pcbToggle, checked: true } });

    const toggle = document.getElementById(pcbToggle);
    if (toggle instanceof HTMLInputElement) {
      toggle.checked = true;
    }
  }
}

export function toggleProductCompareVisibility(event: OnSwitchChange) {
  const pcbLabel = document.getElementById("reptile-pcb-text-label");
  const searchResultElement = document.getElementById("reptile-search-result");

  if (event.target.id == pcbToggle && pcbLabel && searchResultElement) {
    while (pcbLabel.firstChild) {
      pcbLabel.removeChild(pcbLabel.firstChild);
    }
    pcbLabel.appendChild(document.createTextNode(event.target.checked ? switchCheckedLabel : switchUncheckedLabel));

    const hideProductCompareCSS = "reptile_searchResult--hideProductComparison";
    event.target.checked
      ? searchResultElement.classList.remove(hideProductCompareCSS)
      : searchResultElement.classList.add(hideProductCompareCSS);

    toggleProductComparisonCookie(event.target.checked);
    trackToggleSwitch();
  }
}

function toggleProductComparisonCookie(checked: boolean) {
  const productComparisonEnabled = cookie.get(productComparisonEnabledCookie);

  if ((productComparisonEnabled === undefined || productComparisonEnabled === "false") && checked) {
    cookie.set(productComparisonEnabledCookie, "true");
  } else if (productComparisonEnabled === "true" && !checked) {
    cookie.remove(productComparisonEnabledCookie);
  }
}

function trackToggleSwitch() {
  eventQBus.emit("tracking.bct.submitEvent", {
    san_ComparisonActivity: "toggle",
  });
}
