import { eventQBus } from "../types/EventQBus";
import SponsoredSheet from "./SponsoredSheet";
import { event } from "@otto-ec/global-resources/event";
import { SponsoredSheetData } from "../types/SponsoredSheet";

const SPONSORED_CLASS = "js_reptile_sponsored";
const DEAL_CLASS = "find_tile__deal";
const SEARCH_RESULT_ID = "reptile-search-result";

function openSheet(event: Event) {
  if (!event.target || !(event.target instanceof Element)) return;

  const data = getData(event.target);
  if (!data) return;

  SponsoredSheet.open(data);
}

function getData(target: Element): SponsoredSheetData | undefined {
  const dealData = target.closest(".js_deal_data") as HTMLElement;

  if (dealData) {
    const { funderLegalName, advertiserLegalName } = dealData.dataset;

    if (funderLegalName && advertiserLegalName) {
      return {
        funderLegalName,
        advertiserLegalName,
      };
    }
  }

  return;
}

function registerClickHandlersForSponsoredAndDeal() {
  const delegationElement = document.getElementById(SEARCH_RESULT_ID);
  if (delegationElement) {
    event.delegate(delegationElement, "click", `.${SPONSORED_CLASS}`, openSheet);
    event.delegate(delegationElement, "click", `.js_deal_data > .${DEAL_CLASS}`, openSheet);
  }
}

export function registerSponsored() {
  eventQBus.on("ftfind.tilelist.loaded", registerClickHandlersForSponsoredAndDeal);
  eventQBus.on("ftfind.tilelist.restored", registerClickHandlersForSponsoredAndDeal);
  registerClickHandlersForSponsoredAndDeal();
}
