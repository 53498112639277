import { Sheet } from "@otto-ec/global-pattern";

export default class PaliSheet {
  static open(title: string, content: string, tracking: Record<string, string[]>) {
    const sheet: Sheet = new window.o_global.pali.sheet({
      title: title,
      content: content,
      trackingObject: tracking,
    });

    sheet.open();
  }
}
