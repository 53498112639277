import { CompareApi, Status } from "../../types/CompareApi";
import { eventQBus } from "../../types/EventQBus";
import { PandaProductComparisonApi } from "../../types/PandaProductComparisonApi";
import { AdvisorScope } from "../../types/AdvisorScope";

export class PandaCompareApi implements CompareApi {
  private pandaCompareApi: PandaProductComparisonApi | undefined;
  private contextId: string | undefined;

  public constructor() {
    eventQBus.on("ftfind.comparison.loaded", (event: PandaProductComparisonApi | AdvisorScope, compareId: string) =>
      this.onComparisonLoaded(<PandaProductComparisonApi>event, compareId)
    );
  }

  public addProductToComparison(id: string): Promise<Status> {
    if (this.pandaCompareApi && this.contextId) {
      return this.pandaCompareApi.addToList(this.contextId, id).then((_) => "OK");
    }
    return Promise.reject("Panda Compare API or contextId not defined");
  }

  public removeProductFromComparison(id: string): Promise<Status> {
    if (this.pandaCompareApi && this.contextId) {
      return this.pandaCompareApi.removeFromList(this.contextId, id).then(this.mapToStatus);
    }
    return Promise.reject("Panda Compare API or contextId not defined");
  }

  public getSelectedProducts(): Promise<string[]> {
    if (this.pandaCompareApi && this.contextId) {
      return this.pandaCompareApi
        .getList(this.contextId)
        .then((variationIds) => (variationIds !== null ? variationIds : []));
    }
    return Promise.reject("Panda Compare API or contextId not defined");
  }

  public getProductComparisonLink(): Promise<string> {
    if (this.pandaCompareApi && this.contextId) {
      return this.pandaCompareApi
        .getLinkForList(this.contextId)
        .then((link) => (link !== null ? link : Promise.reject("no link for context")));
    }
    return Promise.reject("Panda Compare API or contextId not defined");
  }

  /**
 *
 *
 *
 */
  private onComparisonLoaded(event: PandaProductComparisonApi, compareId: string) {
    if (event) {
      this.pandaCompareApi = event;
      this.contextId = compareId;
      this.getSelectedProducts()
        .then((value) => {
          value.forEach((variationId) => {
            eventQBus.emit(`ftfind.tile.compared`, { variationId: variationId, source: "advisor_scope" });
          });
        })
        .catch((reason) => console.log(reason));
    }
  }

  private mapToStatus(result: string | null): Status {
    if (typeof result == "string") {
      return "OK";
    }
    return "ERROR";
  }
}
