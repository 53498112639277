export class Tile {
  static readonly tileClass = "reptile_sheet-tile";
  static readonly loadingIndicationClass = "reptile_sheet-tile--loading";

  readonly variationId: string;
  readonly tileWrapper: HTMLElement;

  private readonly onDeleteClicked: () => void;

  constructor(variationId: string, onDeleteClicked: () => void) {
    this.variationId = variationId;
    this.onDeleteClicked = onDeleteClicked;
    this.tileWrapper = this.makeTileWrapper();
  }

  public load() {
    if (this.tileWrapper.classList.contains(Tile.loadingIndicationClass)) {
      this.loadTileLite();
    }
  }

  private loadTileLite() {
    fetch(`/crocotile/tile-lite/${this.variationId}`)
      .then((response) => {
        if (response.status === 200) {
          return response.text();
        }
      })
      .then((html) => {
        if (html) {
          this.tileWrapper.classList.remove(Tile.loadingIndicationClass);
          this.tileWrapper.innerHTML = html;
          this.tileWrapper.appendChild(this.makeDeleteIcon());
        }
      });
  }

  private makeTileWrapper(): HTMLElement {
    const tileWrapper = document.createElement("div");
    tileWrapper.classList.add("reptile_sheet-tile", Tile.loadingIndicationClass);
    tileWrapper.innerHTML =
      '<div class="reptile_sheet-pending-tile"><div class="reptile_sheet-pending-tile__image"></div><div class="reptile_sheet-pending-tile__text"></div><div class="reptile_sheet-pending-tile__text"></div></div>';
    return tileWrapper;
  }

  private makeDeleteIcon(): HTMLElement {
    const icon = document.createElement("div");
    icon.classList.add("reptile_sheet-delete-icon");
    icon.addEventListener("click", this.onDeleteClicked);

    icon.innerHTML = `<button type="button" class="pl_rounded50"><svg class="pl_icon" role="img" aria-hidden="true"><use xlink:href="/assets-static/icons/pl_icon_delete.svg#pl_icon_delete"/></svg></button>`;
    return icon;
  }
}
