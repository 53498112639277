import { eventQBus } from "../types/EventQBus";
import { MiniAction } from "../types/Tracking";

const OBSERVER_OPTIONS = {
  root: null,
  threshold: 1.0,
};

export function onIntersect(entries: Partial<IntersectionObserverEntry>[], observer: IntersectionObserver) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const tile = entry.target as HTMLElement;
      const position = tile.getAttribute("data-local-list-position");

      if (position !== null) {
        const featureId = "find_tile_" + position;
        const miniAction: MiniAction = { featureId: featureId, action: "scroll", status: "visible" };
        eventQBus.emit("tracking.bct.submitMiniActions", [miniAction]);
      }

      observer.unobserve(tile);
    }
  });
}

function onTileListLoaded() {
  const lazyTiles = document.querySelectorAll("article.product");
  const observer = new IntersectionObserver(onIntersect, OBSERVER_OPTIONS);

  Array.from(lazyTiles).forEach((tile) => observer.observe(tile));
}

export function registerTileScrolling() {
  onTileListLoaded(); /*                                                                            */
  eventQBus.on("ftfind.tilelist.loaded", onTileListLoaded);
}
