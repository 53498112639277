import { Page } from "../types/Tilelist";
import { core } from "@otto-ec/global-resources/core";

type QueryParamKey = string;
type QueryParamValue = undefined | string | number | boolean | (string | number | boolean)[];

/**
 *
 *
 *
 *
 *
 *
 *
 */
function parseQueryParams(queryString: string) {
  const params: Record<QueryParamKey, QueryParamValue> = {};

  queryString
    .split("&")
    .filter((chunk) => chunk !== "")
    .forEach((chunk) => {
      const splittedChunk = chunk.split("=");
      const key = splittedChunk[0];
      if (splittedChunk.length > 1) {
        const valArray = (splittedChunk[1] || "").split(",").map((val) => core.coerce(val));
        params[key] = valArray.length === 1 ? valArray[0] : valArray;
      } else {
        params[key] = undefined;
      }
    });
  return params;
}

/**
 *
 *
 *
 *
 *
 *
 */
function formatQueryParams(obj: object) {
  return Object.keys(obj)
    .map((key) => {
      if ((obj as keyof object)[key] === undefined) {
        return key;
      } else {
        const objKey = (obj as keyof object)[key] || [];
        const objKeyAsArray = Array.isArray(objKey) ? objKey : [objKey];
        return `${key}=${objKeyAsArray.join(",")}`;
      }
    })
    .join("&");
}

/**
 *
 *
 *
 */
export function getQuery(queryString: string) {
  const { o, l, sortiertnach, c, ...parameters } = parseQueryParams(queryString.slice(1));
  return {
    parameters: parameters,
    page: {
      o: o,
      l: l,
      sortiertnach: sortiertnach,
      c: c,
    },
  };
}

/**
 *
 *
 *
 *
 *
 */
export function isSamePage(currentPage: Page, historyPage: Page): boolean {
  return (
    currentPage.l === historyPage.l &&
    currentPage.sortiertnach === historyPage.sortiertnach &&
    currentPage.o === historyPage.o
  );
}

/**
 *
 *
 *
 *
 */
export function pagedUrl(page: Page, path: string, queryParams: string): string {
  const queryString = formatQueryParams({
    ...getQuery(queryParams).parameters,
    ...page,
  });

  return queryString ? `${path}?${queryString}` : path;
}

export function setLocation(target: string | undefined) {
  if (target == undefined) {
    return;
  }
  window.location.href = target;
}
