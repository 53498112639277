import { eventQBus } from "../types/EventQBus";
import { breakpoint } from "@otto-ec/global-resources/breakpoint";

const triggerPageLoadTopic = "ftfind.dresonRule.resolve";

function isBottomPaging(btn: Element) {
  return btn.closest(".reptile_paging--bottom") != null;
}

function isMobile() {
  return breakpoint.isSmall() || breakpoint.isMedium();
}

/**
 *
 *
 *
 */
function requestPageLoad(event: Event) {
  const target = event.target as Element;
  if (!target) return;

  const tileListBracket = document.getElementById("reptile-tilelist-bracket");
  const paging = target.closest<HTMLElement>(".js_reptile_paging");
  const pagingBlock = target.closest<HTMLElement>(".reptile_paging");
  const btn = target.closest<HTMLElement>(".js_pagingLink");

  /*                         */
  if (!paging || !pagingBlock || !btn || !tileListBracket) return;

  const rule = tileListBracket.dataset.rule || "";
  const page = (btn.dataset.page && JSON.parse(btn.dataset.page)) || {};

  const tsLink: PagingTrackingLabels = (btn.dataset.tsLink && JSON.parse(btn.dataset.tsLink)) || {};
  if (pagingBlock.classList.contains("reptile_paging--bottom")) {
    tsLink.san_Interaction = "paging_bottom";
  }
  if (pagingBlock.classList.contains("reptile_paging--top")) {
    tsLink.san_Interaction = "paging_top";
  }

  const shouldScroll = isBottomPaging(btn) || isMobile();

  eventQBus.emit(triggerPageLoadTopic, {
    rule,
    page,
    tsLink,
    shouldScroll,
  });
}

/**
 *
 *
 *
 */
function create(elem: Element) {
  elem.addEventListener("click", requestPageLoad);
}

function reinit() {
  Array.from(document.getElementsByClassName("js_reptile_paging")).forEach(create);
}

export function registerPaging() {
  reinit();
  eventQBus.on("ftfind.tilelist.loaded", reinit);
}

export type PagingTrackingLabels = {
  san_Interaction: "paging_bottom" | "paging_top";
  san_NaviPaging: string;
};
