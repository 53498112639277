import { eventQBus } from "../types/EventQBus";
import { TilelistLoadEvent } from "../types/Tilelist";

/**
 *
 *
 *
 */
function requestPageLoad(event: Event) {
  const tilelistBracket = document.getElementById("reptile-tilelist-bracket");
  const sorting = event.target as HTMLSelectElement;
  const option = sorting?.options[sorting.selectedIndex];

  /*                         */
  if (option === null || tilelistBracket === null) return;

  const { rule } = tilelistBracket.dataset;
  const { page, tsLink } = option.dataset;

  if (rule) {
    eventQBus.emit("ftfind.dresonRule.resolve", {
      rule: rule,
      page: (page && JSON.parse(page)) || {},
      tsLink: (tsLink && JSON.parse(tsLink)) || {},
    });
  }
}

/**
 *
 */
function onClick() {
  eventQBus.emit("tracking.bct.submitEvent", { san_Foldout: "sorting_open" });
}

/**
 *
 *
 *
 */
function create(elem: Element) {
  elem.addEventListener("change", requestPageLoad);
  elem.addEventListener("click", onClick, true);
}

/**
 *
 */
export function addSortEventListenerToElements() {
  Array.from(document.getElementsByClassName("js_sorting")).forEach((elem: Element) => create(elem));
}

export type SortTopics = {
  "ftfind.dresonRule.resolve": [TilelistLoadEvent];
};

export type SortTrackingLabels =
  | {
      san_Foldout: "sorting_open";
    }
  | {
      san_Interaction: "sorting_change";
      san_SortingChange: string;
    }
  | {
      san_Interaction: string;
      san_SortingChange: string;
      ts_RemoveLabels: string;
    };
