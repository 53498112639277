import { eventQBus } from "../types/EventQBus";
import { OnSimilarProductSheetOpen } from "../types/Tile";
import { sheet } from "@otto-ec/otto-components/sheet/v1";
import { core } from "@otto-ec/global-resources/core";
import { similarProductsSheetTemplate } from "./SimilarProductsSheetTemplate";
import { logscope } from "../utils/Logger";

enum SELECTOR {
  IMAGE_WEBP = "reptile_similar-products-sheet_imageSourceWebp",
  IMAGE_JPEG = "reptile_similar-products-sheet_imageSourceJpeg",
  IMAGE = "reptile_similar-products-sheet_image",
  PDP_BUTTON = "reptile_similar-products-sheet_pdp_button",
  SHEET = "reptile_similar-products-sheet",
  IMAGE_CONTAINER = ".reptile_similar-products-sheet_imageContainer",
  SIMILAR_PRODUCTS_CONTAINER = ".reptile_similar-container",
}

const log = logscope.scope("similar-products-sheet");

export class SimilarProductsSheet {
  private constructor(
    private classList: DOMTokenList,
    layout: string | undefined,
    data: OnSimilarProductSheetOpen,
  ) {
    const { imageSourceWebpUrl, imageSourceJpegUrl, imageUrl, imageAltTag, adsLink, variationId } = data;
    this.imageSourceWebp?.setAttribute("srcset", imageSourceWebpUrl);
    this.imageSourceJpeg?.setAttribute("srcset", imageSourceJpegUrl);
    this.image?.setAttribute("src", imageUrl);
    this.image?.setAttribute("alt", imageAltTag);
    if (!!this.image) {
      this.image.onload = (e) => {
        const height = this.image?.naturalHeight ?? 0;
        const width = this.image?.naturalWidth ?? 0;
        if (height >= width) {
          this.imageContainer?.style.setProperty("aspect-ratio", "1 / 1");
          (e.target as HTMLImageElement).style.height = "100%";
        } else {
          (e.target as HTMLImageElement).style.width = "100%";
          this.imageContainer?.style.setProperty(
            "aspect-ratio",
            `${this.image?.naturalWidth} / ${this.image?.naturalHeight}`,
          );
        }
      };
    }
    this.pdpButton?.setAttribute("href", adsLink);

    this.loadSimilarProducts(variationId, layout);
  }

  get imageSourceWebp(): HTMLSourceElement | null {
    return document.getElementById(SELECTOR.IMAGE_WEBP) as HTMLSourceElement | null;
  }

  get imageSourceJpeg(): HTMLSourceElement | null {
    return document.getElementById(SELECTOR.IMAGE_JPEG) as HTMLSourceElement | null;
  }

  get image(): HTMLImageElement | null {
    return document.getElementById(SELECTOR.IMAGE) as HTMLImageElement | null;
  }

  get pdpButton(): HTMLElement | null {
    return document.getElementById(SELECTOR.PDP_BUTTON) as HTMLElement | null;
  }

  get imageContainer(): HTMLElement | null {
    return document.querySelector(SELECTOR.IMAGE_CONTAINER) as HTMLElement | null;
  }

  get similarProductsList(): HTMLElement | null {
    return document.querySelector(SELECTOR.SIMILAR_PRODUCTS_CONTAINER) as HTMLElement | null;
  }

  static createSheetMarkupIfNotExist() {
    const sheet = document.getElementById(SELECTOR.SHEET) as HTMLElement;
    if (sheet != null) return;
    const sheetPlaceholder: HTMLElement = document.getElementById(
      "reptile_similar-products-sheet-placeholder",
    ) as HTMLElement;
    if (sheetPlaceholder == null) return;
    sheetPlaceholder.outerHTML = similarProductsSheetTemplate();
  }

  static create(
    classList: DOMTokenList,
    layout: string | undefined,
    data: OnSimilarProductSheetOpen,
  ): SimilarProductsSheet | null {
    if (!data) return null;
    return new SimilarProductsSheet(classList, layout, data);
  }

  loadSimilarProducts(variationId: string, layout: string | undefined): void {
    this.clearSimilarProducts();

    const queryParams: Record<string, string> = { variationId };
    if (layout) queryParams.layout = layout;
    const query = core.serialize(queryParams);

    fetch(`/everglades/products/similar?${query}`)
      .then(this.extractHtmlFragment)
      .then((html) =>
        setTimeout(() => {
          log.info("render similar products");
          this.replaceSimilarProducts(html);
        }, 0),
      )
      .then(() => eventQBus.emit("reptile.similar_product_sheet.loaded"));
  }

  private extractHtmlFragment(response: Response) {
    if (response.status !== 200) {
      throw Error(`Failed to retrieve similar products: ${response.status} ${response.statusText}`);
    }
    return response.text();
  }

  private replaceSimilarProducts(html: string) {
    const range = document.createRange();
    const referenceNode = this.similarProductsList;
    if (referenceNode) {
      range.selectNode(referenceNode);
      const fragment = range.createContextualFragment(html);
      fragment.getElementById("reptile_similar-tilelist")?.classList.add(...this.classList);
      range.deleteContents();
      range.insertNode(fragment);
    }
  }

  private clearSimilarProducts() {
    const dummySkeleton = document.getElementById("reptile_dummy_skeleton");
    if (dummySkeleton) {
      document
        .querySelectorAll(SELECTOR.SIMILAR_PRODUCTS_CONTAINER + " .find_tile")
        .forEach((target) => (target.innerHTML = dummySkeleton.innerHTML));
    } else {
      const referenceNode = this.similarProductsList;
      if (referenceNode) referenceNode.innerHTML = "";
    }
  }
}

/**
 *
 *
 *
 *
 *
 */
export function registerSimilarProductSheet() {
  const sheetPlaceholder: HTMLElement = document.getElementById(
    "reptile_similar-products-sheet-placeholder",
  ) as HTMLElement;
  if (sheetPlaceholder != null) {
    const similarListClassList = sheetPlaceholder.classList;
    const layout = sheetPlaceholder.dataset.layout;
    eventQBus.on("reptile.tile.similarProductsOpen", (data: OnSimilarProductSheetOpen) => {
      SimilarProductsSheet.createSheetMarkupIfNotExist();
      sheet.open(SELECTOR.SHEET);
      SimilarProductsSheet.create(similarListClassList, layout, data);
    });
  } else {
    eventQBus.once("ftfind.tilelist.loaded", registerSimilarProductSheet);
  }
}
