import Slider from "./Slider";
import { Feature, TrackingLabels } from "../../types/Tracking";
import { resolveLinkType } from "../ProductTileClickHandling";

export type NewsSliderTrackingLabels = {
  san_FilledSlotsNewsSlider?: number;
  san_Interaction?: string;
  "wk.san_ProductClickListType"?: string;
  "wk.san_ProductLinkType"?: string;
  "wk.san_ProductType"?: string;
  san_ProductType?: string;
  "wk.san_SliderPosition"?: number;
  san_VariationCount?: number;
};

export default class NewsSlider extends Slider {
  private type: string = "NEWS";

  protected getPathSegment(): string {
    return "news-slider";
  }

  protected isType(type: string | undefined): boolean {
    return this.type === type;
  }

  protected getClickTrackingLabel(position: number, ev: MouseEvent): TrackingLabels {
    if (this.avContent) {
      return {
        san_Interaction: "news_slider_product_tile",
        "wk.san_ProductClickListType": this.avContent.dataset.pt,
        "wk.san_ProductLinkType": resolveLinkType(ev),
        "wk.san_ProductType": "news_slider",
        "wk.san_SliderPosition": +position,
      };
    }

    return {};
  }

  protected getBaseTrackingLabel(count: number): TrackingLabels {
    return {
      san_FilledSlotsNewsSlider: count,
    };
  }

  protected prepareFeatureTracking(currentSlides: number[]): Feature[] {
    return [
      <Feature>{
        id: "san_news_slider",
        name: "News-Slider",
        status: "loaded",
        labels: {},
      },
    ].concat(
      currentSlides.map((index) => {
        const tile: HTMLElement = document.getElementsByClassName(this.sliderItemClassName).item(index) as HTMLElement;
        const position = tile.dataset.position || "";
        const variationCount = tile.dataset.variationCount || "";
        return <Feature>{
          id: "san_news_tile_" + position,
          name: "News-Slider-Tile",
          status: "loaded",
          parentId: "san_news_slider",
          position: +position,
          variationId: tile.dataset.variationId,
          labels: {
            san_ProductType: "news_slider",
            san_VariationCount: +variationCount,
          },
        };
      }),
    );
  }
}
