type MatchFunction = (elem: HTMLElement) => boolean;

/**
 *
 */
export function findMatch(
  element: HTMLElement | null,
  matchCond: MatchFunction,
  maxParent: HTMLElement | null
): HTMLElement | boolean {
  if (!element || !maxParent) {
    return false;
  }

  if (matchCond(element)) {
    return element;
  }
  if (element === maxParent) {
    return false;
  }
  return !!element.parentNode && findMatch(element.parentElement, matchCond, maxParent);
}

/**
 *
 */
export function invokePreloadPolyfill() {
  const tilelistBracket = document.getElementById("reptile-tilelist-bracket") as HTMLElement | null;

  if (tilelistBracket && window.preload_polyfill_invoke) {
    window.preload_polyfill_invoke(tilelistBracket);
  }
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function cloneAndReplace(source: HTMLElement | null, dest: HTMLElement | null) {
  if (!source || !dest) {
    return;
  }

  const clone = source.cloneNode(true);
  if (clone instanceof HTMLElement && dest.parentNode) {
    clone.id = dest.id;
    dest.parentNode.replaceChild(clone, dest);
  }
}
