import { eventQBus } from "../types/EventQBus";
import { OnTileLoaded } from "../types/Tile";

const MAX_POSITION = 5;
let initialized = false;

export type FireBaseLabels = {
  event_name: string;
  event_params: FirebaseEventParameter;
};

type FirebaseEventParameter = {
  item_id: string;
};

/**
 *
 *
 *
 *
 */
export function trackArticle(article: HTMLElement, sponsoredArticles: number) {
  const { listPosition = 0, articleNumber } = article.dataset;
  if (articleNumber && +listPosition > sponsoredArticles && +listPosition <= MAX_POSITION + sponsoredArticles) {
    eventQBus.emit("ft-apps.firebase.eventTracking", {
      event_name: "view_item",
      event_params: { item_id: articleNumber },
    });
  }
}

/**
 *
 *
 *
 */
function onLoadedTile(registration: OnTileLoaded) {
  const { variationId } = registration;
  const sponsoredArticles = document.querySelectorAll(".product.spa").length;
  const article = document.querySelector(`.product[data-variation-id="${variationId}"]:not(.spa)`);

  if (article) {
    trackArticle(<HTMLElement>article, sponsoredArticles);
  }
}

/**
 *
 */
export function registerFirebase() {
  if (initialized) {
    return;
  }
  initialized = true;
  eventQBus.on("ftfind.tile.loaded", onLoadedTile);
}
