import { Sheet } from "@otto-ec/global-pattern";

export default class DiscountbenefitPaliSheet {
  static open(benefitId: string, dresonRule: string, variationId: string) {
    const sheet: Sheet = new window.o_global.pali.sheet({});
    sheet.setContentFromURL(
      `/benefit-sheet/sheet/list/${benefitId}?contextDreson=${dresonRule}&variationId=${variationId}`,
    );
    sheet.settings.lockMobileHeight = false;
    sheet.open();
  }
}
