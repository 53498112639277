import { Serializable } from "@otto-ec/global-utils";

export class RatingSliderRequestParams {
  private avContent: HTMLElement;
  private tileListId = "reptile-tilelist";

  constructor(avContent: HTMLElement) {
    this.avContent = avContent;
  }

  private get rule(): object {
    const rule = this.avContent.dataset.rule;
    if (rule) {
      return { rule: rule };
    }
    return {};
  }

  private get sliderSearchContext(): object {
    const tileList = document.getElementById(this.tileListId) as HTMLElement;
    const sliderSearchContext = tileList ? tileList.getAttribute("data-sliderSearchContext") ?? "" : "";
    if (sliderSearchContext) {
      return { sliderSearchContext: sliderSearchContext };
    }
    return {};
  }

  get requestParams(): Serializable {
    return { ...this.rule, ...this.sliderSearchContext };
  }
}
