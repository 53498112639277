import { logger, status } from "@otto-ec/global-resources/debug";

let initialized = false;
const COUNT_PRODUCTS = 4;

/**
 *
 *
 *
 *
 */
function articleInfo(products: NodeListOf<HTMLElement>) {
  const loopCount = Math.min(products.length, COUNT_PRODUCTS);
  const pi = [];
  const vi = [];
  let i;
  for (i = 0; i < loopCount; i++) {
    const { articleNumber, variationId } = products[i].dataset;
    pi.push(articleNumber);
    vi.push(variationId);
  }
  return { pi, vi };
}

/**
 *
 *
 *
 */
export function exacTagCallback() {
  const avContent = document.getElementById("avContent");
  const products = document.querySelectorAll(".product:not(.sponsored)") as NodeListOf<HTMLElement>;
  if (avContent == null) {
    return null;
  }
  const { menuitempath, pt } = avContent.dataset;
  const { pi, vi } = articleInfo(products);

  return {
    pt: pt, /*         */
    pi: pi.join(","), /*             */
    vi: vi.join(","), /*            */
    sp: menuitempath, /*       */
  };
}

/**
 *
 */
export function registerExacTag() {
  if (initialized) {
    return;
  }
  initialized = true;
  const log = logger("reptile.tilelist.ExacTag");

  try {
    window.lhotse.exactag.register("sanProductList", exacTagCallback);
  } catch (e) {
    if (status().activated) log.debug("error: " + e);
  }
}
